import React from 'react'
import Maps from '../pagesTemplates/maps'
import gatsbyi18Context from '../contexts/gatsby-i18n-context.js'

export default (props) => {
  const { pageContext } = props
  const { langKey: language } = pageContext

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <Maps {...props} />
    </gatsbyi18Context.Provider>
  )
}

export const query = graphql`
  query {
    allWordpressWpMap(
      sort: {
        fields: acf___map_order
        order: ASC
      }
      filter: {
        polylang_current_lang: {
          eq: "ar"
        }
      }
    ) {
      nodes {
        id
        wordpress_id
        title
        excerpt
        slug
        acf {
          type
          styles_ids
        }
      }
    }

    instructions: wordpressPage(
      slug: {
        eq: "how-to-use-archive"
      }
    ) {
      title
      slug
      content
    }
  }


`
